.wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.buttonStore {
    display: block;
    margin: 0 6px 6px 0;
}
