.rootContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.subContainer {
    display: flex;
    flex-direction: column;
}

.subContent {
    display: flex;
    flex-direction: column;
}

.section {
    margin: 0 16px 16px 0;
}

.title {
    margin: 0 0 10px 8px;
    text-transform: uppercase;
}

.link {
    margin-bottom: 8px;
    padding: 8px;
    border-radius: 6px;
    background: #f0f4f7;
    color: #18181b;
    width: fit-content;
}

.link:hover {
    background: #fcfcfd;
}

@media (--snow-tablet) {
    .rootContainer {
        flex-direction: row;
    }
    .subContainer:last-child {
        flex: 1;
        flex-direction: row;
        justify-content: space-around;
    }
    .section:last-child {
        margin: 0 16px 0 0;
    }
}

@media (--snow-desktop) {
    .subContainer {
        flex: 1;
        display: flex;
        flex-direction: row;
        margin: 0;
    }
    .section {
        flex: 1;
    }
}
