.root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: rgba(34, 34, 34, 0.12);
    /*
    It fixes annoying for users content loading loaders of above search widgets while footer is fully in visible
    viewport. For more information see: https://blog.chromium.org/2017/04/scroll-anchoring-for-web-developers.html
    Scroll anchoring doesn't work in iOS at all. This CSS rule disables it for another browsers too.
    But scroll anchoring might be helpful if user wants to read footer information but there is no limits for amount of
    loading content in the widgets above. So, we need to delete this hack after conceptual fixes in search widgets (like
    max amount of auto loading pages). User could read footer information only after all products scrolling for now.
    */
    overflow-anchor: none;
}
