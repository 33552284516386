.wrapper {
    width: 100%;
    padding: 12px 0 64px;
    display: flex;
    flex-direction: column;
}

.icon {
    margin: 0 4px 0 0;
}

.item {
    padding: 0 12px 0 0;
}

.itemInner {
    display: flex;
    flex-direction: column;
}

.links {
    display: flex;
    flex-direction: column;
    padding: 24px 0 12px;
}

.linksInner {
    display: flex;
    flex-direction: column;
}

.linksItem:last-child {
    margin: 0;
}

.link {
    margin: 0 24px 12px 0;
}

.item:last-child {
    margin: 12px 0 0 0;
}

.vatNote {
    display: block;
    color: #898993;
    margin: 12px 0 0 0;
}

@media (--snow-tablet) {
    .wrapper {
        flex-direction: row;
        flex-wrap: wrap;
        padding: 12px 0 28px;
    }

    .itemInner {
        max-width: 165px;
    }

    .item:first-child {
        order: 3;
        margin: 0 0 0 auto;
        flex-basis: 25%;
    }

    .item:nth-child(2) {
        flex-basis: 100%;
        order: -1;
    }

    .item:last-child {
        margin: 0;
    }

    .links {
        padding: 4px 0 12px;
    }

    .linksInner {
        flex-direction: row;
    }

    .linksItem:last-child {
        order: -1;
        margin: 0 0 12px;
    }

    .vatNote {
        margin: 6px 0 0 0;
    }
}

@media (--snow-desktop) {
    .wrapper {
        flex-wrap: nowrap;
        padding: 22px 0 16px;
    }

    .item:first-child {
        flex-basis: 25%;
        order: 0;
        margin: 0;
    }

    .item:nth-child(2) {
        flex-basis: 35%;
        margin: 0 auto 0 0;
        order: 0;
    }

    .item:last-child {
        flex-basis: 25%;
    }

    .itemInner {
        max-width: 240px;
    }

    .linksInner {
        flex-direction: column;
    }

    .links {
        flex-direction: column;
        padding: 0 12px 0 0;
    }

    .link {
        flex-basis: auto;
    }
}

@media (--snow-desktop-xl) {
    .linksInner {
        flex: 1;
    }

    .links {
        flex-direction: row;
    }

    .linksItem {
        flex: 1;
        padding: 0 24px 0 0;
    }

    .item:first-child {
        flex: 1;
    }

    .item:nth-child(2) {
        flex: 2;
    }

    .item:last-child {
        flex: 1;
    }
}
